import React from 'react';
import Navbar from './global-components/navbar-v2';
import Banner from './section-components/banner-v2';
import Footer from './global-components/footer';
import Packages from './packages';
import ContactModal from './global-components/contact-modal';

const Home_V1 = () => {
    return <div className='background'>
                <Navbar />
                <Banner />
                <ContactModal/>
                <Packages />
                <ContactModal/>
                <Footer />
            </div>
}

export default Home_V1

