import React from 'react';
import Navbar from './global-components/navbar-v2';
import PageHeader from './global-components/page-header';
import Footer from './global-components/footer';
import Convencional from './convencional';

const BoothType = (type) => {
    return <div>
        <Convencional/>
        
    </div>
}

export default BoothType

