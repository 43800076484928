import React from 'react';
import ServicesModal from './services';

const Packages = () => {
    return <div className='packages-out-container'> 
            <h4>O puedes contratar alguno de nuestros paquetes:</h4>
                <div className="packages-container">
                    <div className="package-row row">
                        <div className='col package-price-container '>
                            <div className='package-title'>
                                <h5>Diversión Desatada</h5>
                            </div>
                            <div className='package-list'>
                                <div className='package-list-item'>2 horas de photobooth</div>
                                <div className='package-list-item'>1 álbum de fotos impreso</div>
                                <div className='package-list-item'>Impresiones ilimitadas</div>
                            </div>
                            <div className='price'>
                                <br/>
                                <p>Precio de Paquete: <b>₡170.000</b></p>
                            </div>
                        </div>

                        <div className='col package-price-container'>
                            <div className='package-title'>
                                <h5>Diversión 360°</h5>
                            </div>
                            <div className='package-list'>
                                <div className='package-list-item'>2 horas de video 360°</div>
                                <div className='package-list-item'>Rótulos Personalizados (Al gusto del cliente)</div>
                            </div>
                            <div className='price'>
                                <br/>
                                <p>Precio de paquete: <b>170.000</b></p>
                            </div>
                        </div>

                        <div className='col package-price-container'>
                            <div className='package-title'>
                                <h5>Diversión en Conjunto</h5>
                            </div>
                            <div className='package-list'>
                                <div className='package-list-item'>1 hora de photobooth</div>
                                <div className='package-list-item'>1 hora de video 360°</div>
                                <div className='package-list-item'>Álbum de fotos impreso</div>
                                <div className='package-list-item'>Rótulos personalizados (Al gusto del cliente)</div>
                            </div>
                            <div className='price'>
                                <p>Precio regular: <b>250.000</b></p>
                                <p>Precio de paquete: <b>₡200.000</b></p>
                                <p>(Ahorras <b>₡50.000</b>)</p>    
                            </div>
                        </div>

                        <div className='col package-price-container'>
                            <div className='package-title'>
                                <h5>Instante Mágico</h5>
                            </div>
                            <div className='package-list'>
                                <div className='package-list-item'>1.5 horas de photobooth</div>
                                <div className='package-list-item'>1.5 horas de video 360°</div>
                                <div className='package-list-item'>Álbum de fotos impreso</div>
                            </div>
                            <div className='price'>
                                <p>Precio regular: <b>₡290.000</b></p>
                                <p>Precio de paquete: <b>₡240.000</b></p>
                                <p>(Ahorras <b>₡40.000</b>)</p>    
                            </div>
                        </div>

                        <div className='col package-price-container'>
                            <div className='package-title'>
                                <h5>Momentos Infinitos</h5>
                            </div>
                            <div className='package-list'>
                                <div className='package-list-item'>2 horas de video 360°</div>
                                <div className='package-list-item'>1 hora de photobooth</div>
                                <div className='package-list-item'>Álbum de fotos impreso</div>
                                <div className='package-list-item'>Impresiones ilimitadas</div>
                            </div>
                            <div className='price'>
                                <p>Precio regular: <b>₡295.000</b></p>
                                <p>Precio de paquete: <b>₡260.000</b></p>
                                <p>(Ahorras <b>₡35.000</b>)</p>    
                            </div>
                        </div>

                        <div className='col package-price-container'>
                            <div className='package-title'>
                                <h5>Noche Mágica</h5>
                            </div>
                            <div className='package-list'>
                                <div className='package-list-item'>3 horas de photobooth</div>
                                <div className='package-list-item'>1 hora de video 360°</div>
                            </div>
                            <div className='price'>
                                <p>Precio regular: <b>₡340.000</b></p>
                                <p>Precio de paquete: <b>₡305.000</b></p>
                                <p>(Ahorras <b>₡35.000</b>)</p>    
                            </div>
                        </div>

                        <div className='col package-price-container'>
                            <div className='package-title'>
                                <h5>Experiencia Envolvente</h5>
                            </div>
                            <div className='package-list'>
                                <div className='package-list-item'>3 horas de video 360°</div>
                                <div className='package-list-item'>2 horas de photobooth</div>
                                <div className='package-list-item'>Álbum de fotos impreso</div>
                            </div>
                            <div className='price'>
                                <p>Precio regular: <b>₡385.000</b></p>
                                <p>Precio de paquete: <b>₡340.000</b></p>
                                <p>(Ahorras <b>₡45.000</b>)</p>    
                            </div>
                        </div>

                        <div className='col package-price-container'>
                            <div className='package-title'>
                                <h5>Celebración Total</h5>
                            </div>
                            <div className='package-list'>
                                <div className='package-list-item'>4 horas de photobooth</div>
                                <div className='package-list-item'>3 horas de video 360°</div>
                                <div className='package-list-item'>Álbum de fotos impreso</div>
                            </div>
                            <div className='price'>
                                <p>Precio regular: <b>₡475.000</b></p>
                                <p>Precio de paquete: <b>₡420.000</b></p>
                                <p>(Ahorras <b>₡55.000</b>)</p>    
                            </div>
                        </div>                   
                        
                        <div className='col package-price-container'>
                            <div className='package-title'>
                                <h5>Celebración 360°</h5>
                            </div>
                            <div className='package-list'>
                                <div className='package-list-item'>4 horas de video 360°</div>
                                <div className='package-list-item'>3 horas de photobooth</div>
                                <div className='package-list-item'>Álbum de fotos impreso</div>
                            </div>
                            <div className='price'>
                                <p>Precio regular: <b>₡475.000</b></p>
                                <p>Precio de paquete: <b>₡410.000</b></p>
                                <p>(Ahorras <b>₡65.000</b>)</p>    
                            </div>
                        </div>

                    </div>
                </div>
            </div>
}

export default Packages