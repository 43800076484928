import React, { Component } from "react";
import Navbar from "./global-components/navbar-v2";
import PageHeader from "./global-components/page-header";
import Footer from "./global-components/footer";
import ContactModal from "./global-components/contact-modal";
class Dj extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div className="booth-background">
        <Navbar />
        <PageHeader
          headertitle="¡Contamos con DJ Profesional!"
          subheader="DJ"
        />
        <section className="contact-page-area pd-top-100 pd-bottom-70">
          <div className="booth-container row">
            <div className="booth-text booth-type col-6-lg">
              <h4 className="color-black">DJ Profesional: Mauricio</h4>
              <p className="color-black">
                Convierte tu evento en una experiencia inolvidable con nuestro
                talentoso DJ profesional, Mauricio. Con 26 años de experiencia
                en la industria, Mauricio ha animado todo tipo de eventos, desde
                bodas hasta fiestas corporativas. Su pasión por la música y su
                habilidad para leer la multitud aseguran que cada momento esté
                lleno de energía y diversión.
              </p>
              <br />
              <h4 className="color-black">
                Calidad y Servicio de Primera Clase
              </h4>
              <p className="color-black">
                En Dreams Photo Booth, nos comprometemos a ofrecer la más alta
                calidad en entretenimiento para tu evento. Nuestro DJ, Maurico,
                no solo te ofrece una amplia selección de música para adaptarse
                a tus gustos y preferencias, sino que también brinda un servicio
                personalizado y profesional desde la primera consulta hasta el
                último baile de la noche.
              </p>
              <br />
              <h4 className="color-black">Haz Que Tu Evento Brille</h4>
              <p className="color-black">
                Desde los clásicos atemporales hasta los últimos éxitos de las
                listas de éxitos, Mauricio se asegurará de que la música en tu
                evento sea impecable. Ya sea que estés planeando una boda
                íntima, una fiesta de cumpleaños o un evento corporativo, confía
                en Dreams Photo Booth y nuestro DJ profesional para hacer que tu
                evento brille. Contáctanos hoy para reservar tu fecha
              </p>
            </div>
            <div className="booth-type booth-image col-6-lg">
              <img
                className="dj-header-image"
                src={publicUrl + "assets/img/dj.JPG"}
                alt="DJ Mauricio"
              />
            </div>
          </div>

          <ContactModal />

          <div className="dj-info-container">
            <div className="row">
              <div className="dj-box dj-services-container">
                <ul>
                  <li>Neblina Led</li>
                  <li>Luces decorativas para la sala</li>
                  <li>Micrófonos inalámbricos</li>
                  <li>26 años de experiencia en eventos.</li>
                  <li>Montaje nítido con señales inalámbricas para evitar cables</li>
                  <li>Música acorde a evento (Playlist)</li>
                  <li>Todo género de música: mp3, mp4</li>
                </ul>
              </div>
              <div className="dj-box dj-audio-container">
                <ul>
                  <li>Q-Luz negra (Neón)</li>
                  <li>Luz láser</li>
                  <li>Columpio</li>
                  <li>Luces Inteligentes: Cabezas robóticas chauvet intimidator spot led 350</li>
                  <li>Luz Led</li>
                  <li>Esfera de espejos</li>
                </ul>
                Amplificación nítida en QSC ,qsc kw181, qsc kw153 -Reproducción
                en tornamesas technics 1200 (diseño de lujo) -Sistema interfaz
                Mac -mixer RANE -mixer mackie
              </div>
            </div>

            <div className="row">
              <div className="dj-box dj-lights-container">
                <ul>
                  <li>Q-Luz negra (Neón)</li>
                  <li>Luz láser</li>
                  <li>Columpio</li>
                  <li>
                    Luces Inteligentes: Cabezas robóticas chauvet intimidator
                    spot led 350
                  </li>
                  <li>Luz Led</li>
                  <li>Esfera de espejos</li>
                </ul>
              </div>
              <div className="dj-box dj-structure-container">
                <ul>
                  <li>Q-Luz negra (Neón)</li>
                  <li>Luz láser</li>
                  <li>Columpio</li>
                  <li>
                    Luces Inteligentes: Cabezas robóticas chauvet intimidator
                    spot led 350
                  </li>
                  <li>Luz Led</li>
                  <li>Esfera de espejos</li>
                </ul>
                -Estructura truss araña de 5X5m o 6X6m -Cabina de audio de
                respaldo
              </div>
            </div>
            <div className="dj-box dj-team-container"></div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}

export default Dj;
